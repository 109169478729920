<template>
  <b-modal id="modal-upload-progress" class="upload-progress-modal" :hide-header="true" :hide-footer="true" centered no-close-on-esc no-close-on-backdrop>
    <div v-if="progress != 100 && progress != -1">
      <h2>Bild hochladen</h2>
      <div class="progress-bar">
        <div class="progress-bar-inside" :style="{ width: progress + '%' }"></div>
      </div>
    </div>

    <div v-else-if="progress == -1" class="grid place-items-center">
      <h2>Bild wird verarbeitet</h2>

      <!-- https://loading.io/css/ -->
      <div class="spinner-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div v-else class="flex flex-col justify-center">
      <h2>Abgeschlossen</h2>
      <img src="../../assets/svg/upload-success.svg" alt="Upload success graphic" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'UploadProgress',
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss">
#modal-upload-progress {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;

  @media (max-width: 768px) {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  + .modal-backdrop {
    background-color: #fff;
    opacity: 0.9;
  }

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 365px;
    }

    .modal-content {
      border: none;
      background: transparent;

      .modal-body {
        box-shadow: none !important;
        h2 {
          text-align: center;
          font-size: 2.188rem;
          line-height: 1.1;
          font-weight: bold;
          color: #58585a;
          margin-bottom: 1.75rem;

          @media (max-width: 768px) {
            font-size: 2rem;
            margin-bottom: 1.25rem;
          }
        }

        .progress-bar {
          position: relative;
          width: 100%;
          background-color: #fff1d5;
          height: 0.5rem;

          .progress-bar-inside {
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 0.5rem;
            background-color: #f7a600;
            transition: width 0.1s linear;
          }
        }

        img {
          margin: 0 auto;
        }

        .spinner-ring {
          display: inline-block;
          position: relative;
          width: 5rem;
          height: 5rem;

          div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 4rem;
            height: 4rem;
            margin: 0.5rem;
            border: 0.5rem solid #f7a600;
            border-radius: 50%;
            animation: spinner-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #f7a600 transparent transparent transparent;

            &:nth-child(1) {
              animation-delay: -0.45s;
            }

            &:nth-child(2) {
              animation-delay: -0.3s;
            }

            &:nth-child(3) {
              animation-delay: -0.15s;
            }
          }
        }
      }
    }
  }
}

@keyframes spinner-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
