<template>
  <div id="app">
    <full-page id="fullpage" ref="fullpage" :options="options" :skip-init="true">
      <div class="section landing--section">
        <LandingSection></LandingSection>
      </div>
      <div class="section material--section">
        <MaterialSection></MaterialSection>
      </div>
      <div v-if="current_image_main && show_editor" class="section editor--section fp-section fp-table">
        <EditorSection></EditorSection>
      </div>
      <div v-if="current_output_image && show_preview" class="section preview--section fp-section fp-table">
        <PreviewSection></PreviewSection>
      </div>
      <div v-if="cart_items.length" class="section cart--section fp-section fp-table">
        <CartSection></CartSection>
      </div>
      <div v-if="cart_items.length && show_account" class="section account--section fp-section fp-table">
        <AccountSection></AccountSection>
      </div>
      <div v-if="cart_address" class="section buy--section fp-section fp-table">
        <BuySection />
      </div>
    </full-page>
  </div>
</template>

<script>
import 'tailwindcss/tailwind.css';
import LandingSection from './sections/LandingSection.vue';
import MaterialSection from './sections/MaterialSection.vue';
import EditorSection from './sections/EditorSection.vue';
import PreviewSection from './sections/PreviewSection.vue';
import CartSection from './sections/CartSection.vue';
import AccountSection from './sections/AccountSection.vue';
import BuySection from './sections/BuySection.vue';
import { mapState } from 'vuex';
/* global fullpage_api */

export default {
  name: 'App',
  components: {
    LandingSection,
    MaterialSection,
    EditorSection,
    PreviewSection,
    CartSection,
    AccountSection,
    BuySection
  },
  data() {
    return {
      afterRefresh: true,
      options: {
        // scrollOverflow: true,
        // preventScroll: true,
        fitToSection: false,
        fitToSectionDelay: 500,
        controlArrows: true,
        licenseKey: '24D021B7-63DA4FFE-9ECF7AE3-40EBE787',
        autoScrolling: false,
        setLockAnchors: true
      }
    };
  },
  computed: {
    ...mapState({
      cart_items: (state) => state.cart.cart_items,
      current_image_main: (state) => state.cart.current_image,
      cart_address: (state) => state.cart.billing_address.address_line_1,
      show_preview: (state) => state.cart.show_preview,
      show_editor: (state) => state.cart.show_editor,
      show_account: (state) => state.cart.show_account,
      current_output_image(state) {
        if ((!state.cart.current_output_image || state.cart.current_output_image.includes('blob')) && this.afterRefresh) {
          this.afterRefresh = false;
          return null;
        } else {
          this.afterRefresh = false;
          return state.cart.current_output_image;
        }
      }
    })
  },
  mounted() {
    // add google tag script
    let googleScript = document.createElement('script');
    googleScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-1007480-1');
    googleScript.setAttribute('async', '');
    document.head.appendChild(googleScript);

    let googleLocalScript = document.createElement('script');
    googleLocalScript.text =
      "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-1007480-1', { 'anonymize_ip': true});";
    document.head.appendChild(googleLocalScript);

    this.$refs.fullpage.init();
    fullpage_api.setAllowScrolling(true);
    fullpage_api.setKeyboardScrolling(true);
    fullpage_api.setMouseWheelScrolling(true);
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&display=swap');

body {
  padding-right: 0 !important;

  &.modal-open {
    overflow: hidden !important;
  }
}

#app {
  font-family: 'Titillium Web', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  @media (max-width: 991px) {
    padding-top: 88px;
  }
}
.landing--section {
  position: relative;
  z-index: 50;
}

.section,
.fp-tableCell {
  height: unset !important;
  min-height: 100vh;

  @media (max-width: 991px) {
    min-height: unset;
  }
}

.section-lines {
  display: flex;
  overflow: hidden;
  padding-left: 2.75rem;
  padding-right: 2.75rem;

  @media (max-width: 991px) {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

div.container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  max-width: none;

  @media (max-width: 991px) {
    min-height: 500px;
  }
}

div.container-inner {
  padding: 70px;
  padding-right: 150px;
  padding-left: 150px;
}

div.container-inner {
  width: 100% !important;
}

.btn {
  border-radius: 30px;
  transition: 0.3s;
  padding: 0.2rem 0.8rem;
  min-width: 14.125rem;
  text-align: center;
  appearance: none;

  &:focus {
    box-shadow: none !important;
  }

  &.btn-primary {
    background-color: rgb(247, 166, 0);
    border-color: rgb(247, 166, 0);
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: bold;

    &:hover,
    &:active,
    &:focus {
      background-color: rgb(253, 190, 60) !important;
      border-color: rgb(253, 190, 60) !important;
    }
  }

  &.btn-secondary {
    color: #ffffff;
    font-size: 1.25rem;
    background-color: rgb(88, 88, 90);
    border-color: rgb(88, 88, 90);

    &:hover,
    &:active,
    &:focus {
      background-color: rgb(124, 124, 123);
      border-color: rgb(124, 124, 123);
    }
  }

  &.btn-hollow {
    color: rgb(88, 88, 90);
    font-size: 1.25rem;
    background-color: transparent;
    border-color: rgb(88, 88, 90);

    &:hover,
    &:active,
    &:focus {
      color: white;
      background-color: rgb(88, 88, 90);
      border-color: rgb(88, 88, 90);
    }
  }

  &.btn-mobile {
    @media (max-width: 768px) {
      min-width: unset;
      max-width: fit-content;
      width: 100%;
      font-size: 0.813rem;
      line-height: 1.25rem;
      padding: 0.5rem 0.8rem;
      white-space: nowrap;
    }
  }
}

.title {
  font-weight: bold;
  //font-size: 2.875rem;
  line-height: 3.125rem;
  font-size: clamp(1.8rem, calc(1.8rem + ((1vw - 0.48rem) * 1.4931)), 2.875rem);

  /*@media (max-width: 1200px) {
    font-size: 2.5rem;
    line-height: 2.813rem;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 2.813rem;
  }*/

  &.primary {
    color: rgb(248, 165, 0);
  }

  &.secondary {
    color: rgb(88, 88, 90);
  }
}
</style>
