<template>
  <b-container class="container section-lines" fluid>
    <div class="container-inner" style="display: flex; align-items: center; justify-content: center; width: 100%">
      <div style="width: 100%" class="align-self-baseline">
        <b-row class="text-left">
          <b-col class="form-login-col">
            <div class="text-center">
              <div v-if="!user_logged_in && current_mode == 'login'" class="account-container">
                <h2 v-if="!user_registered" class="md:py-10 md:pb-32 title heading-account font-bold">Login</h2>

                <div v-else class="md:pt-10">
                  <h2 class="heading-account heading-after-register">
                    Herzlichen Dank<br />
                    für deine Registrierung
                  </h2>
                  <p class="text-lg mb-8">In wenigen Minuten erhältst du von uns ein automatisches Bestätigungs E-Mail mit allen Angaben.</p>
                </div>

                <img v-if="!user_registered" src="../assets/svg/login-icon.svg" class="img-fluid mx-auto mb-4" alt="Login icon" />

                <b-form id="account-section-login-form">
                  <b-form-input id="input-1" v-model="login.email" type="email" placeholder="E-Mail" required class="input-account-section"></b-form-input>
                  <b-form-input
                    id="input-2"
                    v-model="login.password"
                    placeholder="Passwort"
                    type="password"
                    class="input-account-section"
                    required
                  ></b-form-input>
                  <span id="lost-password-account" class="lost-password" @click="userRegisterSideMenu()">Passwort vergessen?</span>

                  <span id="login-error-2" class="hidden text-center text-red-500 pb-3 text-lg"
                    >E-Mail oder Passwort falsch. Bitte überprüfe die Eingaben.</span
                  >

                  <b-button type="submit" class="mx-auto text-xl submit-login-btn btn-hollow" block @click.prevent="userLogin()">Login</b-button>
                  <span v-if="!user_registered" class="register-now pt-3 pb-1 block" @click="current_mode = 'register'">Kein Kunde? Jetzt registieren</span>
                </b-form>
              </div>

              <div v-if="!user_logged_in && current_mode == 'register'" class="register-account-wrapper">
                <svg
                  class="inline-block cursor-pointer absolute top-0 right-4"
                  width="30"
                  height="30"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  @click="current_mode = 'login'"
                >
                  <g stroke="rgb(112, 112, 112)" stroke-width="1">
                    <line x1="0" y1="0" x2="30" y2="30" />
                    <line x1="0" y1="30" x2="30" y2="0" />
                  </g>
                </svg>

                <h2 class="md:py-10 title heading-account font-bold">Registrieren</h2>
                <img src="../assets/svg/login-icon.svg" class="img-fluid mx-auto mb-4" alt="Login icon" />

                <b-form id="account-register-form" class="account-section-login-form">
                  <!--<b-form-select id="account-register-input-0" v-model="account.salutation" :options="['Herr', 'Frau', 'Firma']" class="input-account-section form-control"></b-form-select>-->
                  <b-form-select id="account-register-input-0" v-model="account.salutation" class="input-account-section form-control">
                    <b-form-select-option value="null" disabled>Bitte Anrede auswählen</b-form-select-option>
                    <b-form-select-option value="Herr">Herr</b-form-select-option>
                    <b-form-select-option value="Frau">Frau</b-form-select-option>
                    <b-form-select-option value="Firma">Firma</b-form-select-option>
                  </b-form-select>

                  <b-form-input
                    v-if="account.salutation == 'Firma'"
                    id="account-register-input-1"
                    v-model="account.company"
                    type="text"
                    placeholder="Firma"
                    class="input-account-section form-control"
                  ></b-form-input>

                  <b-form-input
                    id="account-register-input-2"
                    v-model="account.surname"
                    type="text"
                    placeholder="Vorname"
                    required
                    class="input-account-section form-control"
                  ></b-form-input>
                  <b-form-input
                    id="account-register-input-3"
                    v-model="account.name"
                    type="text"
                    placeholder="Nachname"
                    required
                    class="input-account-section form-control"
                  ></b-form-input>

                  <b-form-input
                    id="account-register-input-4"
                    v-model="account.address"
                    type="text"
                    placeholder="Adresszusatz"
                    class="input-account-section form-control"
                  ></b-form-input>
                  <b-form-input
                    id="account-register-input-5"
                    v-model="account.street"
                    type="text"
                    placeholder="Strasse"
                    required
                    class="input-account-section form-control"
                  ></b-form-input>

                  <div class="flex gap-2">
                    <b-form-input
                      id="account-register-input-6"
                      v-model="account.postal_code"
                      type="text"
                      placeholder="PLZ"
                      required
                      class="input-account-section form-control"
                      @keypress="numbersOnly"
                    ></b-form-input>
                    <b-form-input
                      id="account-register-input-7"
                      v-model="account.city"
                      type="text"
                      placeholder="Ort"
                      required
                      class="input-account-section form-control"
                    ></b-form-input>
                  </div>

                  <b-form-input
                    id="account-register-input-8"
                    v-model="account.telephone"
                    type="tel"
                    placeholder="Telefon"
                    required
                    class="input-account-section form-control"
                    @keypress="numbersOnly"
                  ></b-form-input>
                  <b-form-input
                    id="account-register-input-9"
                    v-model="account.email"
                    type="email"
                    placeholder="E-Mail"
                    required
                    class="input-account-section form-control"
                  ></b-form-input>

                  <b-form-input
                    id="account-register-input-10"
                    v-model="account.password"
                    type="password"
                    placeholder="Passwort"
                    required
                    class="input-account-section form-control"
                  ></b-form-input>
                  <b-form-input
                    id="account-register-input-11"
                    v-model="account.password_repeat"
                    type="password"
                    placeholder="Passwort wiederholen"
                    required
                    class="input-account-section form-control"
                  ></b-form-input>

                  <span id="register-error-account" class="hidden text-center text-red-500 pb-3 text-lg"></span>

                  <b-button type="submit" class="mx-auto text-xl submit-login-btn btn-hollow mt-8" block @click.prevent="userRegisterAccount()"
                    >Registrieren</b-button
                  >
                </b-form>
              </div>

              <div v-if="user_logged_in">
                <h2 class="md:py-10 title heading-account font-bold">Hallo</h2>
                <p class="text-2xl font-bold heading-account">
                  <span v-if="user_data.name || user_data.surname">{{ user_data.surname }} {{ user_data.name }}</span>
                  <span v-else>{{ user_data.company }}</span>
                  <br />
                  {{ user_data.email }}
                </p>
                <b-button class="mb-0 btn-primary w-25 mt-12" variant="primary" @click="userLogout()">Abmelden</b-button>
              </div>
            </div>
          </b-col>
          <div class="line-col w-px m-0 hidden md:block"></div>
          <b-col class="form-register-col">
            <div>
              <div class="register-container">
                <h2 class="py-8 md:py-10 title heading-account font-bold">
                  <span v-if="user_logged_in" class="md:inline-block">Rechnungsadresse</span>
                  <span v-else class="md:inline-block">Als Gast bestellen</span>
                </h2>
              </div>
              <b-form id="account-billing-address-form">
                <div class="register-account-wrapper">
                  <b-row class="register-item-row align-items-center flex-col md:flex-row">
                    <b-col class="custom-label-col">
                      <label class="text-lg" for="account-input-0">Anrede</label>
                    </b-col>
                    <b-col class="custom-input-col">
                      <!--<b-form-select id="account-input-0" v-model="user_data.salutation" :options="['Herr', 'Frau', 'Firma']" class="input-side-menu"></b-form-select>-->
                      <b-form-select id="account-input-0" v-model="user_data.salutation" class="input-side-menu">
                        <b-form-select-option value="" disabled>Bitte Anrede auswählen</b-form-select-option>
                        <b-form-select-option value="Herr">Herr</b-form-select-option>
                        <b-form-select-option value="Frau">Frau</b-form-select-option>
                        <b-form-select-option value="Firma">Firma</b-form-select-option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </div>

                <div v-if="user_data.salutation == 'Firma'" class="register-account-wrapper">
                  <b-row class="register-item-row align-items-center flex-col md:flex-row">
                    <b-col class="custom-label-col">
                      <label class="text-lg" for="account-input-1">Firma</label>
                    </b-col>
                    <b-col class="custom-input-col">
                      <b-form-input
                        id="account-input-1"
                        v-model="user_data.company"
                        placeholder="Firmenname"
                        required
                        class="input-account-section"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </div>

                <div class="register-account-wrapper">
                  <b-row class="register-item-row align-items-center flex-col md:flex-row">
                    <b-col class="custom-label-col">
                      <label class="text-lg" for="account-input-2">Vorname*</label>
                    </b-col>
                    <b-col class="custom-input-col">
                      <b-form-input
                        id="account-input-2"
                        v-model="user_data.surname"
                        placeholder="Vorname"
                        required
                        class="input-account-section"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </div>

                <div class="register-account-wrapper">
                  <b-row class="register-item-row align-items-center flex-col md:flex-row">
                    <b-col class="custom-label-col">
                      <label class="text-lg" for="account-input-3">Nachname*</label>
                    </b-col>
                    <b-col class="custom-input-col">
                      <b-form-input id="account-input-3" v-model="user_data.name" placeholder="Nachname" class="input-account-section"></b-form-input>
                    </b-col>
                  </b-row>
                </div>

                <div class="register-account-wrapper">
                  <b-row class="register-item-row align-items-center flex-col md:flex-row">
                    <b-col class="custom-label-col">
                      <label class="text-lg" for="account-input-4">Adresszusatz</label>
                    </b-col>
                    <b-col class="custom-input-col">
                      <b-form-input
                        id="account-input-4"
                        v-model="user_data.billing_address.address"
                        placeholder="Adresszusatz"
                        class="input-account-section"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </div>

                <div class="register-account-wrapper">
                  <b-row class="register-item-row align-items-center flex-col md:flex-row">
                    <b-col class="custom-label-col">
                      <label class="text-lg" for="account-input-5">Strasse*</label>
                    </b-col>
                    <b-col class="custom-input-col">
                      <b-form-input
                        id="account-input-5"
                        v-model="user_data.billing_address.street"
                        placeholder="Strasse*"
                        required
                        class="input-account-section"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </div>

                <div class="register-account-wrapper wrapper-with-more-elements mb-3 flex flex-col xl:flex-row">
                  <div class="flex align-items-center flex-col md:flex-row">
                    <div class="custom-label-col-first">
                      <label class="text-lg" for="account-input-6">PLZ*</label>
                    </div>

                    <div class="custom-input-col-first">
                      <b-form-input
                        id="account-input-6"
                        v-model="user_data.billing_address.postal_code"
                        placeholder="PLZ*"
                        required
                        class="input-account-section"
                        @keypress="numbersOnly"
                      ></b-form-input>
                    </div>
                  </div>

                  <div class="flex align-items-center w-full flex-col md:flex-row">
                    <div class="custom-label-col-second">
                      <label class="text-lg" for="account-input-57">Ort*</label>
                    </div>

                    <div class="custom-input-col-second w-full">
                      <b-form-input
                        id="account-input-7"
                        v-model="user_data.billing_address.city"
                        placeholder="Ort*"
                        required
                        class="input-account-section"
                      ></b-form-input>
                    </div>
                  </div>
                </div>

                <div class="register-account-wrapper">
                  <b-row class="register-item-row align-items-center flex-col md:flex-row">
                    <b-col class="custom-label-col">
                      <label class="text-lg" for="account-input-8">Telefon*</label>
                    </b-col>
                    <b-col class="custom-input-col">
                      <b-form-input
                        id="account-input-8"
                        v-model="user_data.telephone"
                        placeholder="Telefon*"
                        required
                        class="input-account-section"
                        @keypress="numbersOnly"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </div>

                <div class="register-account-wrapper">
                  <b-row class="align-items-center mb-6 md:mb-10 2xl:mb-16 flex-col md:flex-row">
                    <b-col class="custom-label-col">
                      <label class="text-lg" for="account-input-9">E-Mail*</label>
                    </b-col>
                    <b-col class="custom-input-col">
                      <b-form-input
                        id="account-input-9"
                        v-model="user_data.email"
                        type="email"
                        placeholder="E-Mail*"
                        required
                        class="input-account-section"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </div>
              </b-form>
            </div>

            <div class="checkbox-wrapper">
              <b-row>
                <b-col>
                  <div class="check-el">
                    <input id="checkbox-1" v-model="use_delivery_address" type="checkbox" name="use_delivery_address" class="custom-checkbox" />
                    <label for="checkbox-1" class="text-lg custom-checkbox-form">Abweichende Lieferadresse erfassen</label>
                  </div>
                </b-col>
              </b-row>
            </div>

            <div v-if="use_delivery_address">
              <div>
                <div class="register-container">
                  <h2 class="py-8 md:py-10 title heading-account font-bold">
                    <span class="inline-block">Lieferadresse</span>
                  </h2>
                </div>
                <b-form id="account-shipping-address-form">
                  <div class="register-account-wrapper">
                    <b-row class="register-item-row align-items-center flex-col md:flex-row">
                      <b-col class="custom-label-col">
                        <label class="text-lg" for="account-input-00">Anrede</label>
                      </b-col>
                      <b-col class="custom-input-col">
                        <!--<b-form-select id="account-input-00" v-model="user_data.shipping_address.salutation" :options="['Herr', 'Frau', 'Firma']" class="input-side-menu"></b-form-select>-->
                        <b-form-select id="account-input-00" v-model="user_data.salutation" class="input-side-menu">
                          <b-form-select-option :value="null" disabled>Bitte Anrede auswählen</b-form-select-option>
                          <b-form-select-option value="Herr">Herr</b-form-select-option>
                          <b-form-select-option value="Frau">Frau</b-form-select-option>
                          <b-form-select-option value="Firma">Firma</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </div>

                  <div v-if="user_data.shipping_address.salutation == 'Firma'" class="register-account-wrapper">
                    <b-row class="register-item-row align-items-center flex-col md:flex-row">
                      <b-col class="custom-label-col">
                        <label class="text-lg" for="account-input-9">Firma</label>
                      </b-col>
                      <b-col class="custom-input-col">
                        <b-form-input
                          id="account-input-9"
                          v-model="user_data.shipping_address.company"
                          placeholder="Firmenname"
                          required
                          class="input-account-section"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="register-account-wrapper">
                    <b-row class="register-item-row align-items-center flex-col md:flex-row">
                      <b-col class="custom-label-col">
                        <label class="text-lg" for="account-input-10">Vorname*</label>
                      </b-col>
                      <b-col class="custom-input-col">
                        <b-form-input
                          id="account-input-10"
                          v-model="user_data.shipping_address.surname"
                          placeholder="Vorname"
                          required
                          class="input-account-section"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="register-account-wrapper">
                    <b-row class="register-item-row align-items-center flex-col md:flex-row">
                      <b-col class="custom-label-col">
                        <label class="text-lg" for="account-input-11">Nachname*</label>
                      </b-col>
                      <b-col class="custom-input-col">
                        <b-form-input
                          id="account-input-11"
                          v-model="user_data.shipping_address.name"
                          placeholder="Nachname"
                          class="input-account-section"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="register-account-wrapper">
                    <b-row class="register-item-row align-items-center flex-col md:flex-row">
                      <b-col class="custom-label-col">
                        <label class="text-lg" for="account-input-12">Adresszusatz</label>
                      </b-col>
                      <b-col class="custom-input-col">
                        <b-form-input
                          id="account-input-12"
                          v-model="user_data.shipping_address.address"
                          placeholder="Adresszusatz"
                          class="input-account-section"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="register-account-wrapper">
                    <b-row class="register-item-row align-items-center flex-col md:flex-row">
                      <b-col class="custom-label-col">
                        <label class="text-lg" for="account-input-13">Strasse*</label>
                      </b-col>
                      <b-col class="custom-input-col">
                        <b-form-input
                          id="account-input-13"
                          v-model="user_data.shipping_address.street"
                          placeholder="Strasse*"
                          required
                          class="input-account-section"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="register-account-wrapper wrapper-with-more-elements mb-3 flex flex-col xl:flex-row">
                    <div class="flex align-items-center flex-col md:flex-row">
                      <div class="custom-label-col-first">
                        <label class="text-lg" for="account-input-14">PLZ*</label>
                      </div>

                      <div class="custom-input-col-first">
                        <b-form-input
                          id="account-input-14"
                          v-model="user_data.shipping_address.postal_code"
                          placeholder="PLZ*"
                          required
                          class="input-account-section"
                          @keypress="numbersOnly"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="flex align-items-center w-full flex-col md:flex-row">
                      <div class="custom-label-col-second">
                        <label class="text-lg" for="account-input-15">Ort*</label>
                      </div>

                      <div class="custom-input-col-second w-full">
                        <b-form-input
                          id="account-input-15"
                          v-model="user_data.shipping_address.city"
                          placeholder="Ort*"
                          required
                          class="input-account-section"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>

                  <div class="register-account-wrapper">
                    <b-row class="register-item-row align-items-center flex-col md:flex-row">
                      <b-col class="custom-label-col">
                        <label class="text-lg" for="account-input-16">Telefon</label>
                      </b-col>
                      <b-col class="custom-input-col">
                        <b-form-input
                          id="account-input-16"
                          v-model="user_data.shipping_address.telephone"
                          placeholder="Telefon"
                          class="input-account-section"
                          @keypress="numbersOnly"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="register-account-wrapper">
                    <b-row class="align-items-center mb-6 md:mb-10 2xl:mb-16 flex-col md:flex-row">
                      <b-col class="custom-label-col">
                        <label class="text-lg" for="account-input-17">E-Mail</label>
                      </b-col>
                      <b-col class="custom-input-col">
                        <b-form-input
                          id="account-input-17"
                          v-model="user_data.shipping_address.email"
                          type="email"
                          placeholder="E-Mail"
                          class="input-account-section"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                </b-form>
              </div>
            </div>

            <div class="register-buttons pt-8 md:pt-12 lg:pt-16 2xl:pt-60">
              <b-button class="mb-0 md:mb-4 mr-3 btn-hollow w-25" @click="abort()">Zurück zum Warenkorb </b-button>
              <b-button class="mb-0 btn-primary md:min-w-0 md:pl-5 md:pr-5 xl:pl-10 xl:pr-10 xl:min-w-25" variant="primary" @click="addAddressToBasket()"
                >Zur Kasse</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Connection to api lost modal -->
    <ConnectionLost />
  </b-container>
</template>

<script>
import { moveTo } from '@/utils/fullpage';
import shop from '../api/shop';
import { mapState } from 'vuex';
import ConnectionLost from '../components/modals/ConnectionLost.vue';
import emailjs from 'emailjs-com';

export default {
  name: 'AccountSection',
  components: {
    ConnectionLost
  },
  props: {
    msg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      login: {
        email: '',
        password: ''
      },
      account: {
        salutation: null,
        company: '',
        surname: '',
        name: '',
        address: '',
        street: '',
        postal_code: '',
        city: '',
        telephone: '',
        email: '',
        password: '',
        password_repeat: ''
      },
      current_mode: 'login',
      user_registered: false,
      use_delivery_address: false
    };
  },
  computed: {
    ...mapState({
      user_logged_in: (state) => state.user.user_logged_in,
      user_data: (state) => state.user.user_data
    })
  },
  methods: {
    sendEmail(errorMessage, siteTemplate) {
      let templateParams = {
        name: 'Posterkönig Fehler auf Website',
        email: 'info@posterkoenig.ch',
        message: errorMessage,
        template: siteTemplate
      };
      try {
        emailjs.send('service_3riqben', 'template_g7fn465', templateParams, 'R7qisTbbemhPel-Gb');
        //console.log('Email sent!')
      } catch (error) {
        //console.log({error})
      }
    },
    moveTo(x) {
      moveTo(x);
    },
    abort() {
      moveTo(6);
      this.$store.dispatch('wizard/updateWizard', {
        index: 4,
        value: 0
      });
      //setAllowScrolling(true)
    },
    userRegisterSideMenu() {
      moveTo(1),
        setTimeout(() => {
          this.$root.$emit('openSideMenuFromAccount');
        }, 1000);
    },
    numbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    lostPassword() {
      moveTo(1);
    },
    userLogin() {
      if (document.getElementById('account-section-login-form').checkValidity()) {
        shop.userLogin(this.login.email, this.login.password, (data) => {
          if (data.data.message == 'Forbidden') document.getElementById('login-error-2').style.display = 'inline-block';
          else
            this.$store.dispatch('user/updateUserStatus', true).then(() => {
              this.$store.dispatch('user/getUser', (response) => {
                if (!response) {
                  this.$bvModal.show('modal-connection-lost');
                  this.sendEmail('Die Abfrage des Users ist fehlgeschlagen', 'AccountSection.vue');
                }
              });
            });
        });
      } else document.getElementById('account-section-login-form').reportValidity();
    },
    userRegisterAccount() {
      if (document.getElementById('account-register-form').checkValidity()) {
        document.getElementById('register-error-account').style.display = 'none';

        if (document.getElementById('account-register-input-10').value != document.getElementById('account-register-input-11').value) {
          document.getElementById('register-error-account').style.display = 'block';
          document.getElementById('register-error-account').innerHTML = 'Die Passwörter sind nicht gleich.';
        } else if (document.getElementById('account-register-input-10').value == document.getElementById('account-register-input-11').value) {
          shop.userRegister(this.account, (data) => {
            if (data.data.status == '200') {
              this.current_mode = 'login';
              this.user_registered = true;

              document.getElementById('register-error-account').style.display = 'none';
              document.getElementById('register-error-account').innerHTML = '';
            } else if (data.data.message) {
              document.getElementById('register-error-account').style.display = 'block';
              document.getElementById('register-error-account').innerHTML = data.data.message;
            }
          });
        }
      } else document.getElementById('account-register-form').reportValidity();
    },
    userLogout() {
      if (this.user_logged_in) {
        shop.userLogout((data) => {
          if (data.data.token) {
            this.$store.dispatch('user/updateUserStatus', false);
            this.$store.commit('user/clearUserData');
          }
        });
      }
    },
    addAddressToBasket() {
      let valid = true,
        billing_address,
        shipping_address;

      if (document.getElementById('account-billing-address-form').checkValidity()) {
        valid = true;
        let salutation = 3;
        if (this.user_data.salutation == 'Herr') salutation = 2;
        else if (this.user_data.salutation == 'Firma') salutation = 1;

        billing_address = {
          salutation: salutation,
          name: this.user_data.name,
          surname: this.user_data.surname,
          company: this.user_data.company || '',
          address: this.user_data.billing_address.address,
          street: this.user_data.billing_address.street,
          city: this.user_data.billing_address.city,
          postal_code: this.user_data.billing_address.postal_code,
          email: this.user_data.email,
          telephone: this.user_data.telephone
        };
        shipping_address = billing_address;
      } else {
        valid = false;
        document.getElementById('account-billing-address-form').reportValidity();
      }

      if (this.use_delivery_address) {
        if (document.getElementById('account-shipping-address-form').checkValidity()) {
          valid = true;

          let salutation = 3;
          if (this.user_data.shipping_address.salutation == 'Herr') salutation = 2;
          else if (this.user_data.shipping_address.salutation == 'Firma') salutation = 1;

          shipping_address = JSON.parse(JSON.stringify(this.user_data.shipping_address));
          //console.log(shipping_address)
          shipping_address.salutation = salutation;
        } else {
          valid = false;
          document.getElementById('account-shipping-address-form').reportValidity();
        }
      }

      if (valid) {
        this.$store.dispatch('cart/saveAddress', {
          billing_address: billing_address,
          shipping_address: shipping_address,
          cb: () => {
            setTimeout(() => {
              this.moveTo(8);
              this.$store.dispatch('wizard/updateWizard', {
                index: 5,
                value: 1
              });
            }, 500);
          }
        });
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Line between two cols */
.line-col {
  height: auto;
  background: orange;
  margin-left: 5.3px;
  margin: 30px 0 120px 0;
}

.container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  max-width: none;
  background: #e9e9e9;

  @media (max-width: 991px) {
    min-height: 500px;
  }
}

.container-inner {
  padding: 60px 120px 70px;
  width: 100%;

  @media (max-width: 1600px) {
    padding-right: 90px;
    padding-left: 90px;
  }

  @media (max-width: 1200px) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media (max-width: 991px) {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 80px;
  }
}

.account-container {
  @media (min-width: 769px) {
    max-width: 349px;
    margin: 0 auto;
  }
}

.account-container input,
button,
a[type='button'] {
  margin-bottom: 15px;
}

.account-container input::placeholder {
  color: lightgrey;
}

.heading-account {
  color: #58585a;

  @media (max-width: 768px) {
    margin-bottom: 1.35rem;
    text-align: center;
  }
}

.input-account-section {
  border-radius: 19px;
  background-color: white;
  color: #bcbcbc;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  margin-bottom: 15px;
  height: 39px;
  color: #58585a;
}

.input-side-menu.custom-select {
  border-radius: 19px;
  background-color: white;
  color: #bcbcbc;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  height: 39px;
  color: #58585a;
}

::placeholder {
  color: #bcbcbc;
}

.submit-login-btn {
  color: #58585a;
  border-color: #58585a;
  max-width: 226px;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 5px;
  margin-bottom: 9px;
}

.register-now,
.lost-password {
  display: inline-block !important;
  margin-bottom: 30px;

  @media (max-width: 1600px) {
    margin-bottom: 4rem;
  }

  @media (max-width: 991px) {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 768px) {
    margin-bottom: 1.75rem;
  }

  color: #58585a;
  font-size: 18px;

  &:hover {
    color: #f7a600;
    cursor: pointer;
  }
}

.form-login-col {
  max-width: calc(50% - 1px);
  flex: 0 1 calc(50% - 1px);

  @media (max-width: 1600px) {
    margin-right: 30px;
    max-width: calc(50% - 31px);
    flex: 0 1 calc(50% - 31px);
  }

  @media (max-width: 991px) {
    margin-right: 10px;
    max-width: calc(50% - 11px);
    flex: 0 1 calc(50% - 11px);
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;
    flex: 0 1 100%;
  }
}

.form-register-col {
  max-width: calc(50% - 1px);
  flex: 0 1 calc(50% - 1px);

  @media (max-width: 1600px) {
    margin-left: 30px;
    max-width: calc(50% - 31px);
    flex: 0 1 calc(50% - 31px);
  }

  @media (max-width: 991px) {
    margin-left: 10px;
    max-width: calc(50% - 11px);
    flex: 0 1 calc(50% - 11px);
  }

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 100%;
    flex: 0 1 100%;
  }
}

.register-container {
  max-width: 425px;
  margin: 0 auto;

  .heading-account {
    padding-bottom: 2.5rem;

    @media (max-width: 768px) {
      padding-bottom: 0.25rem;
    }
  }
}
.heading-after-register {
  font-weight: bold;
  margin-bottom: 1.5rem;
  font-size: 34px;

  @media (max-width: 769px) {
    font-size: 25px;
  }
}

.register-item-row {
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
}

.register-account-wrapper {
  max-width: 630px;
  width: 100%;
  margin: 0 auto;

  .custom-label-col {
    max-width: 120px;
    width: 100%;
    flex: 0 1 120px;
    padding-right: 20px;
    text-align: right;

    @media (max-width: 1600px) {
      max-width: 140px;
      flex: 0 1 140px;
    }

    @media (max-width: 1199px) {
      max-width: 120px;
      flex: 0 1 120px;
      padding-right: 10px;
    }

    @media (max-width: 768px) {
      max-width: unset;
      flex: unset;
      text-align: left;
      padding-right: 0;
      padding-left: 20px;
    }

    label {
      margin-bottom: 0;
      color: #58585a;

      @media (max-width: 1199px) {
        font-size: 1rem;
        line-height: 1.45rem;
      }

      @media (max-width: 768px) {
        font-size: 0.813rem;
        line-height: 1rem;
        margin-bottom: 0.3rem;
      }
    }
  }

  .custom-input-col {
    padding-left: 0;

    input {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      padding-left: 20px;
    }
  }

  &.wrapper-with-more-elements {
    color: #58585a;

    @media (max-width: 1199px) {
      margin-bottom: 0 !important;
    }

    .custom-label-col-first {
      min-width: 106px;
      text-align: right;
      padding-right: 20px;

      @media (max-width: 1600px) {
        min-width: 126px;
      }

      @media (max-width: 1199px) {
        min-width: 106px;
        padding-right: 10px;
      }

      @media (max-width: 768px) {
        min-width: unset;
        width: 100%;
        text-align: left;
        padding-left: 6px;
        padding-right: 0;
      }
    }

    .custom-input-col-first {
      width: 106px;
      padding-right: 20px;

      @media (max-width: 1600px) {
        padding-right: 10px;
      }

      @media (max-width: 1199px) {
        width: 100%;
        padding-right: 0;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .custom-label-col-second {
      text-align: right;
      padding-right: 10px;

      @media (max-width: 1199px) {
        min-width: 106px;
      }

      @media (max-width: 768px) {
        min-width: unset;
        width: 100%;
        text-align: left;
        padding-left: 6px;
        padding-right: 0;
      }
    }

    input,
    label {
      margin-bottom: 0;

      @media (max-width: 1199px) {
        margin-bottom: 1rem;
      }

      @media (max-width: 768px) {
        margin-bottom: 0.5rem;
      }
    }

    label {
      @media (max-width: 1199px) {
        font-size: 1rem;
        line-height: 1.45rem;
      }

      @media (max-width: 768px) {
        font-size: 0.813rem;
        line-height: 1rem;
        margin-bottom: 0.3rem;
        text-align: left;
      }
    }
  }
}

.register-buttons {
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.checkbox-wrapper {
  margin-left: 13%;

  .check-el {
    .custom-checkbox-form {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-bottom: 0;
      align-items: center;
      cursor: pointer;
      color: #58585a;

      @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.45rem;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        border: 1px solid #707070;
        border-radius: 50%;
        position: absolute;
        left: -20px;
      }
    }

    .custom-checkbox {
      appearance: none;
      display: none;
    }
  }

  .custom-checkbox:checked + .custom-checkbox-form {
    &::after {
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      position: absolute;
      left: -16px;
      top: 50%;
      transform: translateY(-50%);
      background: #f7a600;
    }
  }
}
</style>
