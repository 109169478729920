import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
import store from './store';
// vue-fullpage
import 'fullpage.js/vendors/scrolloverflow'; // Optional. When using scrollOverflow:true
import VueFullPage from 'vue-fullpage.js';
import './assets/tailwind.css';

Vue.config.productionTip = false;
Vue.use(VueFullPage);

new Vue({
  render: (h) => {
    return h(App);
  },
  store
}).$mount('#app');
