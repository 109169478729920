<template>
  <b-modal id="modal-image-processing" class="image-processing-modal" :hide-header="true" :hide-footer="true" centered no-close-on-esc no-close-on-backdrop>
    <h2>Der König bereitet dein Bild vor.</h2>
    <div class="processing-progress-bar">
      <div class="progress-line" :style="{ '--size': (stage - 1) * 31 + '%' }"></div>

      <div class="progress-number" :class="{ active: stage >= 1, bounce: stage == 1 }">
        <p class="progress-circle">1</p>

        <div class="number-label">
          <span>Vorbereiten</span>
        </div>
      </div>

      <div class="progress-number" :class="{ active: stage >= 2, bounce: stage == 2 }">
        <p class="progress-circle">2</p>

        <div class="number-label">
          <span>Hochladen</span>
        </div>
      </div>

      <div class="progress-number" :class="{ active: stage >= 3, bounce: stage == 3 }">
        <p class="progress-circle">3</p>

        <div class="number-label">
          <span>Hinzufügen</span>
        </div>
      </div>

      <div class="progress-number" :class="{ active: stage == 4 }">
        <span class="progress-circle">
          <svg xmlns="http://www.w3.org/2000/svg" width="27.278" height="27.278" viewBox="0 0 27.278 27.278">
            <path
              id="Icon_material-shopping-cart"
              data-name="Icon material-shopping-cart"
              d="M9.683,24.822a2.728,2.728,0,1,0,2.728,2.728A2.724,2.724,0,0,0,9.683,24.822ZM1.5,3V5.728H4.228L9.138,16.08,7.3,19.421a2.637,2.637,0,0,0-.341,1.309,2.736,2.736,0,0,0,2.728,2.728H26.05V20.731H10.256a.338.338,0,0,1-.341-.341l.041-.164L11.184,18H21.345a2.715,2.715,0,0,0,2.387-1.4l4.883-8.852a1.333,1.333,0,0,0,.164-.655,1.368,1.368,0,0,0-1.364-1.364H7.242L5.96,3ZM23.322,24.822A2.728,2.728,0,1,0,26.05,27.55,2.724,2.724,0,0,0,23.322,24.822Z"
              transform="translate(-1.5 -3)"
              fill="#fff1d5"
            />
          </svg>
        </span>

        <div class="number-label">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.578" height="14.645" viewBox="0 0 16.578 14.645">
              <path
                id="Path_40"
                data-name="Path 40"
                d="M15.855,20.678c-.748-.4-1.583.374-2.072.834-1.122,1.093-2.072,2.359-3.136,3.51-1.18,1.266-2.273,2.532-3.482,3.769a10.381,10.381,0,0,0-1.9,2.3A37.767,37.767,0,0,0,2.188,28.1c-.834-.633-2.216-1.093-2.187.432C.059,30.519,1.814,32.648,3.109,34a3.286,3.286,0,0,0,2.1,1.208c1.007.058,2.043-1.151,2.647-1.813,1.065-1.151,1.928-2.446,2.906-3.625,1.266-1.554,2.561-3.079,3.8-4.661C15.337,24.131,17.783,21.714,15.855,20.678ZM1.267,28.418a.218.218,0,0,0-.115.029,1.4,1.4,0,0,1-.317-.115h0Q.965,28.246,1.267,28.418Z"
                transform="translate(-0.001 -20.566)"
                fill="#f7a600"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ImageProcessing',
  props: {
    stage: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/css/baseModal.scss';

#modal-image-processing {
  .modal-dialog {
    @media (max-width: 575px) {
      max-width: 278px;
      margin: 0 auto;
    }

    .modal-content {
      .modal-body {
        flex-direction: column;
        align-items: center;

        min-height: 438px;
        padding: 7.5rem 7.25rem 2rem;

        @media (max-width: 991px) {
          padding: 7.5rem 4rem 2rem;
        }

        @media (max-width: 768px) {
          padding: 1.25rem 3.75rem 3.75rem;
          min-height: 556px;
        }

        h2 {
          text-align: center;
          margin-bottom: 2.5rem;

          @media (max-width: 768px) {
            font-size: 1.25rem;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
}
</style>
