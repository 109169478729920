<template>
  <div class="image-bubble hidden lg:block">
    <img :src="current_preview_image" alt="" />
    <p>Dein Bild</p>
    <div class="switch-image" @click="$root.$emit('openImageModal')">
      <img src="../assets/svg/two-arrows.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ImageBubble',
  computed: {
    ...mapState({
      current_preview_image: (state) => state.cart.current_preview_image
    }),
    getSmallerImageUnsplash() {
      if (!this.current_preview_image.includes('unsplash')) return this.current_preview_image;

      const smallerImageURL = new URL(this.current_preview_image);
      const smallerImageURLSearch = smallerImageURL.searchParams;
      smallerImageURLSearch.set('q', '100');
      smallerImageURLSearch.set('w', '300');
      smallerImageURL.search = smallerImageURLSearch.toString();
      return smallerImageURL.toString();
    }
  }
};
</script>

<style lang="scss" scoped>
.image-bubble {
  position: absolute;
  right: 80px;
  bottom: 90px;
  border-radius: 50%;
  text-align: center;

  @media (max-width: 1300px) {
    bottom: 40px;
    right: 40px;
  }

  > img {
    display: block;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-fit: cover;
    box-shadow: 3px 3px 10px #0000006e;
  }

  p {
    font-size: 14px;
    line-height: 1.8;
    font-weight: bold;
    color: #58585a;
    margin-top: 10px;
  }

  .switch-image {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background-color: #f7a600;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #fdbe3c;
    }
  }
}
</style>
