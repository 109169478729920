import ExifReader from 'exifreader';

export const isImageCMYK = function (file) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();

    reader.onload = (event) => {
      const metadata = ExifReader.load(event.target.result, { expanded: true });
      const {
        file: {
          'Color Components': { value }
        }
      } = metadata;

      if (value == 4) resolve(true);
      else resolve(false);
    };

    reader.readAsArrayBuffer(file);

    reader.addEventListener('error', function () {
      reject();
    });
  });
};

export const useFileReader = function (file) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);

    reader.addEventListener('error', function () {
      reject();
    });
  });
};

export const useImageLoad = function (elt, url) {
  return new Promise(function (resolve, reject) {
    elt.src = url;
    elt.onload = () => resolve(elt);
    elt.addEventListener('error', function () {
      reject();
    });
  });
};
