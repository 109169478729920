<template>
  <div>
    <b-navbar toggleable="lg" class="px-0 md:pt-0 lg:pb-14 lg:pt-5" type="custom">
      <b-navbar-brand href="#" class="block lg:hidden"><img class="nav-logo" src="../assets/svg/posterkoenig-logo.svg" /></b-navbar-brand>

      <div class="hidden lg:flex align-items-center justify-between w-full">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav class="justify-end">
          <b-navbar-nav align="space-between">
            <b-dropdown text="Produkte" split split-href="/produkte" right class="nav-item">
              <b-dropdown-item href="/produkte/leinwandfoto/">Leinwandfoto</b-dropdown-item>
              <b-dropdown-item href="/produkte/aluminium-platte/">Foto auf Alu-Dibond</b-dropdown-item>
              <b-dropdown-item href="/produkte/poster/">Poster</b-dropdown-item>
              <b-dropdown-item href="/produkte/foto-auf-acrylglas/">Foto auf Acrylglas</b-dropdown-item>
              <b-dropdown-item href="/produkte/hartschaumplatte/">Foto auf Hartschaum</b-dropdown-item>
              <b-dropdown-item href="/produkte/gallery-print/">Gallery Print</b-dropdown-item>
            </b-dropdown>
            <b-nav-item href="/preise/">Preise</b-nav-item>

            <b-nav-item-dropdown right>
              <template #button-content>Service</template>
              <b-dropdown-item href="/faq">FAQ</b-dropdown-item>
              <b-dropdown-item href="/ueber-uns">Über uns</b-dropdown-item>
              <b-dropdown-item href="/gutscheine">Gutscheine</b-dropdown-item>
              <b-dropdown-item href="/mosaikbild">Mosaikbild</b-dropdown-item>
              <b-dropdown-item href="/fotocollage">Fotocollage</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item href="/kontakt">Kontakt</b-nav-item>
          </b-navbar-nav>
        </b-collapse>

        <div class="navbar-icons flex pl-6 xl:pl-11">
          <div class="icon-account-holder w-8 h-8 rounded-full relative" :class="{ 'menu-active': user_logged_in }">
            <img v-if="!user_logged_in" class="icon-account cursor-pointer absolute top-1/2 left-1/2" src="../assets/account.svg" @click="toggleSideMenu()" />
            <img
              v-if="user_logged_in"
              class="icon-account cursor-pointer absolute top-1/2 left-1/2"
              src="../assets/account-white.svg"
              @click="toggleSideMenu()"
            />
          </div>
        </div>
      </div>

      <div class="flex lg:hidden align-items-center">
        <div class="navbar-icons flex">
          <div class="icon-account-holder w-8 h-8 rounded-full relative" :class="{ 'menu-active': user_logged_in }">
            <img v-if="!user_logged_in" class="icon-account cursor-pointer absolute top-1/2 left-1/2" src="../assets/account.svg" @click="toggleSideMenu()" />
            <img
              v-if="user_logged_in"
              class="icon-account cursor-pointer absolute top-1/2 left-1/2"
              src="../assets/account-white.svg"
              @click="toggleSideMenu()"
            />
          </div>
        </div>

        <button
          type="button"
          aria-label="Toggle navigation"
          class="navbar-toggler p-0 border-0 collapsed"
          aria-expanded="false"
          style="overflow-anchor: none"
          @click="toggleNavigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbar-collapse" class="navbar-collapse">
          <b-navbar-nav align="space-between">
            <b-nav-item-dropdown id="submenu-produkte" right>
              <template #button-content>Produkte</template>
              <b-dropdown-item href="/produkte/leinwandfoto/">Leinwandfoto</b-dropdown-item>
              <b-dropdown-item href="/produkte/aluminium-platte/">Foto auf Alu-Dibond</b-dropdown-item>
              <b-dropdown-item href="/produkte/poster/">Poster</b-dropdown-item>
              <b-dropdown-item href="/produkte/foto-auf-acrylglas/">Foto auf Acrylglas</b-dropdown-item>
              <b-dropdown-item href="/produkte/hartschaumplatte/">Foto auf Hartschaum</b-dropdown-item>
              <b-dropdown-item href="/produkte/gallery-print/">Gallery Print</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item href="/preise/">Preise</b-nav-item>

            <b-nav-item-dropdown id="submenu-service" right>
              <template #button-content>Service</template>
              <b-dropdown-item href="/faq">FAQ</b-dropdown-item>
              <b-dropdown-item href="/ueber-uns">Über uns</b-dropdown-item>
              <b-dropdown-item href="/gutscheine">Gutscheine</b-dropdown-item>
              <b-dropdown-item href="/mosaikbild">Mosaikbild</b-dropdown-item>
              <b-dropdown-item href="/fotocollage">Fotocollage</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item href="/kontakt">Kontakt</b-nav-item>
          </b-navbar-nav>
        </div>
      </div>
    </b-navbar>

    <div class="side-menu text-white w-full fixed lg:absolute" :class="{ 'menu-active': menuActive }">
      <SideMenu @close-menu="toggleSideMenu()" @show-loading="showLoading()"></SideMenu>
    </div>
  </div>
</template>

<script>
import SideMenu from './SideMenu';
//import BDropdownHover from '@/components/BDropdownHover'
import { mapState } from 'vuex';

export default {
  name: 'NavBar',
  components: {
    SideMenu
    // BDropdownHover
  },
  props: {
    msg: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      menuActive: false
    };
  },
  computed: {
    ...mapState({
      user_logged_in: (state) => state.user.user_logged_in
    })
  },
  mounted() {
    if (window.location.hash == '#open-login' || window.location.hash == '#_open-login') {
      this.toggleSideMenu();
    }
    this.$root.$on('openSideMenuFromAccount', () => {
      this.toggleSideMenu();
    });
    let subMenuTogglers = document.querySelectorAll('#navbar-collapse .dropdown-toggle');
    subMenuTogglers.forEach((elt) => {
      elt.addEventListener('click', () => {
        let navbar = document.getElementById('navbar-collapse');
        let id = elt.href.split('#')[1],
          submenu = document.querySelector('#' + id + ' .dropdown-menu');

        if (submenu.style.height != '0px') {
          submenu.style.height = 0;

          let navbarHeight = parseInt(navbar.style.height, 10) - parseInt(submenu.getAttribute('data-height'), 10);
          navbar.style.height = navbarHeight + 'px';
        } else {
          submenu.style.height = submenu.getAttribute('data-height') + 'px';

          let navbarHeight = parseInt(navbar.style.height, 10) + parseInt(submenu.getAttribute('data-height'), 10);
          navbar.style.height = navbarHeight + 'px';
        }
      });
    });

    let subMenus = document.querySelectorAll('#navbar-collapse .dropdown-menu');
    subMenus.forEach((elt) => {
      elt.setAttribute('data-height', elt.scrollHeight);
      elt.style.height = 0;
    });

    let navbar = document.getElementById('navbar-collapse');
    navbar.setAttribute('data-height', navbar.scrollHeight);
    navbar.style.height = 0;
    navbar.classList.add('navbar-hidden');
  },
  methods: {
    toggleSideMenu() {
      this.menuActive = !this.menuActive;
    },
    showLoading() {
      this.$emit('show-loading');
    },
    toggleNavigation() {
      let navbar = document.getElementById('navbar-collapse');

      if (navbar.style.height != '0px') {
        navbar.style.height = 0;
        navbar.classList.add('navbar-hidden');
      } else {
        navbar.style.height = navbar.getAttribute('data-height') + 'px';
        navbar.classList.remove('navbar-hidden');
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
nav.navbar {
  @media (max-width: 1900px) {
    padding-bottom: 1.5rem;
  }

  @media (max-width: 991px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 1.5rem 2rem !important;
    z-index: 9999;
  }

  @media (max-width: 768px) {
    flex-wrap: nowrap;
  }

  .navbar-brand {
    padding: 0;

    img {
      height: 40px;

      @media (max-width: 991px) {
        max-width: 100%;
      }
    }
  }

  .navbar-toggler {
    outline: none !important;
    padding-left: 13px !important;

    .navbar-toggler-icon {
      width: 25px;
      height: 25px;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(88, 88, 90, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
  }

  .navbar-collapse {
    @media (max-width: 991px) {
      position: absolute;
      top: 88px;
      left: 0;
      width: 100%;
      background: #fff;
      padding: 1rem 2rem;
      border-top: 1px solid grey;

      li {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }
  }
  .nav-item {
    position: relative;
    &.btn-group {
      .btn.btn-secondary {
        background-color: transparent;
        border-color: none;
        border-radius: 0;
        border: none;
        color: #212934;
        font-size: 1.125rem;
        transition: all 0.5s ease;
        min-width: auto;
      }
    }
    .btn {
      &.dropdown-toggle {
        padding: 0;
        &:after {
          content: '';
          transform: rotate(45deg);
          border: solid #212934;
          border-width: 0 2px 2px 0;
          padding: 3px;
          margin-left: 0;
          margin-bottom: 4px;
          vertical-align: unset;
          transition: all 0.5s ease;
        }
      }
    }
  }
  .b-dropdown {
    &:hover {
      .btn {
        color: #f8a500 !important;
      }
      .dropdown-toggle {
        &:after {
          border-color: #f8a500 !important;
        }
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    justify-content: space-between;

    @media (min-width: 2100px) {
      max-width: 490px;
    }

    li {
      display: inline-block;
      line-height: 20px;

      &.show {
        .nav-link {
          &.dropdown-toggle {
            &::after {
              border-color: rgba(0, 0, 0, 0.9);
            }
          }
        }
      }

      .nav-link {
        color: #212934;
        font-size: 1.125rem;
        transition: all 0.5s ease;
        font-weight: 400;
        text-decoration-thickness: 1.5px !important;

        &.dropdown-toggle {
          &::after {
            transform: rotate(45deg);
            border: solid #212934;
            border-width: 0 2px 2px 0;
            padding: 3px;
            margin-left: 9px;
            margin-bottom: 3px;
            vertical-align: unset;
            transition: all 0.5s ease;
          }
        }

        &:hover {
          color: #f8a500;
          text-decoration: none;

          &.dropdown-toggle {
            &::after {
              border-color: #f8a500 !important;
            }
          }
        }

        @media (max-width: 1350px) {
          padding-left: 0.3rem !important;
          padding-right: 0.3rem !important;
        }
      }

      &:first-child {
        .nav-link {
          padding-left: 0 !important;
        }
      }

      &:last-child {
        .nav-link {
          padding-right: 0 !important;
        }
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    box-shadow: 1px 1px 30px rgb(0 0 0 / 6%);
    border: none;
    min-width: 14rem;
    padding: 10px 0;
    li {
      display: block;
      width: 100%;
      margin: 0;
      border-bottom: 1px solid #e2e2e2;
      &:last-child {
        border-bottom: none;
      }
    }
    a {
      color: #212934;
      font-size: 1.125rem;
      padding: 12px 20px;
      &:hover {
        background-color: white;
        color: #f8a500;
      }
    }
  }

  .navbar-icons {
    .icon-account-holder {
      flex-shrink: 0;
      transition: background-color 0.45s ease;

      &.menu-active {
        background-color: rgb(247, 166, 0);
      }

      .icon-account {
        transform: translate(-50%, -50%);
      }
    }
  }
}

.side-menu {
  right: -100%;
  top: 80px;
  height: calc(100% - 80px);
  background-color: rgb(88, 88, 90);
  z-index: 99;
  transition: all 0.45s ease;

  @media (max-width: 991px) {
    top: 88px;
    height: calc(100% - 88px);
  }

  @media (max-width: 768px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &.menu-active {
    right: 0;
  }
}

#navbar-collapse {
  transition: height 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 0;

  &.navbar-hidden {
    overflow: hidden;
  }

  > ul {
    > li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;

      &.show {
        > a {
          color: #f8a500;

          &::after {
            rotate: 180deg;
            border-color: #f8a500;
          }
        }
      }

      > a {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 1.5rem !important;
        min-height: 65px;

        &::after {
          margin-left: auto;
          border-color: #212934;
        }

        &:hover {
          background-color: #f9f9fb;
        }
      }

      > .dropdown-menu {
        display: block;
        position: relative;
        top: 0;
        margin: 0;
        padding: 0;
        transition: height 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
        border-radius: 0;

        > li {
          > a {
            display: flex;
            align-items: center;
            padding: 0 1.5rem 0 2.5rem !important;
            min-height: 65px;

            &:hover {
              background-color: #f9f9fb;
            }
          }
        }
      }
    }
  }
}
</style>
