<template>
  <b-modal id="modal-connection-lost" class="connection-lost-modal" :hide-header="true" :hide-footer="true" centered no-close-on-esc no-close-on-backdrop>
    <img class="hidden md:block" src="../../assets/svg/connection-lost.svg" alt="Connection lost graphic" />

    <div class="modal-inside">
      <h2>Verbindungsfehler</h2>
      <h3>Komm später nochmal vorbei!</h3>
      <p>Leider konnte die Verbindung zu unserer Website nicht hergestellt werden. Versuche es später nocheinmal oder meld dich direkt bei uns.</p>

      <div class="links">
        <p>Mail: <a href="mailto:info@posterkoenig.ch">info@posterkoenig.ch</a></p>
        <p>Telefon: <a href="tel:0414495064">041 449 50 64</a></p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ConnectionLost'
};
</script>

<style lang="scss">
@import '../../assets/css/baseModal.scss';

#modal-connection-lost {
  .modal-body {
    padding: 0 7.188rem 0 4.688rem;

    @media (max-width: 768px) {
      padding: 2.5rem 2rem;
    }

    .modal-inside {
      h2 {
        margin-bottom: 0.75rem;
      }

      h3 {
        color: #f7a600;
        margin-bottom: 1.125rem;
      }

      p {
        margin-bottom: 2rem;

        @media (max-width: 768px) {
          margin-bottom: 1.5rem;
        }
      }

      .links {
        display: flex;
        justify-content: space-between;

        @media (max-width: 991px) {
          flex-direction: column;
          gap: 0.5rem;
        }

        p {
          color: #f7a600;
          font-size: 1.125rem;
          line-height: 1.5;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 1rem;
          }

          a:hover {
            color: #f7a600;
          }
        }
      }
    }
  }
}
</style>
