import Vue from 'vue';
// initial state
// shape: [{ id, quantity }]
const state = () => ({
  wizard_status: [0, 0, 0, 0, 0, 0]
});

// getters
const getters = {};

// actions
const actions = {
  updateWizard({ commit }, payload) {
    commit('updateWizard', payload);
  },
  resetWizard({ commit }) {
    commit('resetWizard');
  }
};

// mutations
const mutations = {
  updateWizard(state, payload) {
    Vue.set(state.wizard_status, payload.index, payload.value);
  },
  resetWizard(state) {
    state.wizard_status = [0, 0, 0, 0, 0, 0];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
