<template>
  <b-modal id="modal-order-processing" class="order-processing-modal" :hide-header="true" :hide-footer="true" centered no-close-on-esc no-close-on-backdrop>
    <div class="modal-inside">
      <div>
        <h2>Vielen dank für deine Bestellung.</h2>
        <h3>Der König bereitet nun alles vor.</h3>
        <p>Hast du Fragen zur Bestellung? Das Posterkönig-Team steht dir gerne zu Verfügung.</p>

        <div class="links">
          <p>Mail: <a href="mailto:info@posterkoenig.ch">info@posterkoenig.ch</a></p>
          <p>Telefon: <a href="tel:0414495064">041 449 50 64</a></p>
        </div>
      </div>

      <img class="hidden md:block" src="../../assets/king-animation.svg" alt="Animated king graphic" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'OrderProcessing'
};
</script>

<style lang="scss">
@import '../../assets/css/baseModal.scss';

#modal-order-processing {
  .modal-dialog .modal-content .modal-body {
    padding: 5rem 3.2rem 5.625rem 6.1rem;

    @media (max-width: 991px) {
      padding: 3rem 2.5rem;
    }

    .modal-inside {
      flex-direction: row;

      h2 {
        margin-bottom: 0.75rem;
      }

      h3 {
        color: #f7a600;
        margin-bottom: 1.5rem;
      }

      p {
        margin-bottom: 3.75rem;

        @media (max-width: 768px) {
          margin-bottom: 2rem;
        }
      }

      .links {
        display: flex;
        gap: 1.5rem;

        @media (max-width: 991px) {
          flex-direction: column;
          gap: 0.5rem;
        }

        p {
          color: #f7a600;
          font-size: 1.125rem;
          line-height: 1.5;
          margin: 0;

          @media (max-width: 768px) {
            font-size: 1rem;
          }

          a:hover {
            color: #f7a600;
          }
        }
      }
    }
  }
}
</style>
