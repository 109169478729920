<template>
  <b-container id="material-section" class="container section-lines relative" fluid>
    <SideLine />
    <div class="container-inner text-right">
      <div class="m-auto max-w-7xl">
        <div class="text-center">
          <h2 class="title secondary">Wähle dein Material</h2>
        </div>
      </div>

      <div class="material-boxes justify-content-center mb-6 xl:mb-20 mt-16 xl:mt-16 hidden md:flex">
        <div v-for="(material, index) in materials" :key="material.image" class="material-box-item px-2 pb-4 xl:pb-0 xl:px-1.5 xl:mx-3">
          <div class="text-center item-material-box cursor-pointer" :class="{ 'item-material-active': materialIndex == index }" @click="materialIndex = index">
            <div class="material-box-image inline-block rounded-full">
              <img class="block mx-auto my-0 w-full" :src="baseAPIUrl + material.image" />
            </div>
            <p class="item-material-box-title mt-2 m-b-0">
              <b>{{ material.reference }}</b>
            </p>

            <p class="item-material-box-description mt-2">
              {{ stripTags(material.metaKeywords) }}
            </p>
          </div>
        </div>
      </div>

      <div class="material-swiper block md:hidden pt-12">
        <swiper ref="swiper" class="swiper" :options="swiperOption" @click-slide="clickSlide">
          <swiper-slide v-for="material in materials" :key="material.image">
            <div class="text-center item-material-box cursor-pointer" :class="{ 'item-material-active': current_material == material }">
              <div class="material-box-image inline-block rounded-full">
                <img class="block mx-auto my-0 w-full" :src="baseAPIUrl + material.image" />
              </div>
              <p class="item-material-box-title mt-1.5 mx-auto">
                <b>{{ material.reference }}</b>
              </p>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div v-if="materials[materialIndex]" class="material-box-wrapper xl:mx-auto lg:px-20 my-0">
        <div class="material-box mx-auto my-0 flex align-items-center justify-center">
          <div class="material-box-img cursor-pointer">
            <img class="rounded-full hidden xl:block" :src="baseAPIUrl + materials[materialIndex].image" />
          </div>
          <div class="material-box-text xl:ml-14">
            <p class="material-title title secondary cursor-pointer pt-14 md:pt-0 mb-3 md:mb-2 xl:mb-3">{{ materials[materialIndex].reference }}</p>
            <p class="pb-9 md:pb-6 xl:pb-8">{{ stripTags(materials[materialIndex].metaDescription) }}</p>
            <div class="flex justify-center xl:justify-start">
              <a :href="materials[materialIndex].link" class="btn btn-hollow mb-0 mr-3 xl:mr-0 btn-mobile">Mehr Infos</a>
              <a v-if="!current_image_main" class="btn btn-primary mt-6 md:mt-0 hidden md:inline-block btn-mobile" @click="$bvModal.show('modal-upload-file')"
                >Bild hochladen</a
              >
              <a v-if="current_image_main" class="btn btn-primary mt-6 md:mt-0 hidden md:inline-block btn-mobile" @click="nextSection()">Weiter</a>
            </div>
          </div>
        </div>
      </div>

      <ImageBubble v-if="current_image_main" />
    </div>
  </b-container>
</template>

<script>
import SideLine from '../components/SideLine.vue';
import ImageBubble from '../components/ImageBubble.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { mapState } from 'vuex';
import { moveTo } from '@/utils/fullpage';
import config from '@/utils/config';

export default {
  name: 'MaterialSection',
  components: {
    Swiper,
    SwiperSlide,
    SideLine,
    ImageBubble
  },
  props: {
    msg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // slide: 0,
      // sliding: null,
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 25,
        loop: true,
        loopFillGroupWithBlank: false,
        centeredSlides: true,
        breakpoints: {
          450: {
            slidesPerView: 2.5
          },
          500: {
            slidesPerView: 3
          }
        }
      },
      baseAPIUrl: config.baseAPIUrl,
      materialIndex: 0
    };
  },
  computed: {
    ...mapState({
      materials: (state) => state.shop.materials,
      current_material(state) {
        if (state.cart.current_material) this.materialIndex = state.shop.materials.findIndex((elt) => elt.id === state.cart.current_material.id);
        return state.cart.current_material;
      },
      current_image: (state) => state.cart.current_image,
      show_editor: (state) => state.cart.show_editor,
      current_image_main: (state) => state.cart.current_image
    }),
    swiper() {
      return this.$refs.swiper.$swiper;
    }
  },
  watch: {
    update_swiper() {}
  },
  mounted() {
    this.$root.$on('chooseMaterialFromLandingSection', (material) => {
      this.materialIndex = this.materials.findIndex((elt) => elt == material);
    });
  },
  methods: {
    moveTo(x) {
      moveTo(x);
    },
    nextSection() {
      this.chooseMaterial(this.materialIndex);
      this.$store.commit('cart/showEditor');
      setTimeout(() => {
        this.moveTo(4);
        this.$store.dispatch('wizard/updateWizard', {
          index: 1,
          value: 1
        });
      }, 100);
    },
    stripTags(x) {
      return x && x.replace(/[<]br[^>]*[>]/gi, '');
    },
    clickSlide(index, real_index) {
      // use real_index
      this.materialIndex = real_index;
      this.nextSection();
    },
    chooseMaterial(index) {
      const material = this.materials[index];
      if (this.current_image_main) {
        if (this.show_editor) this.$root.$emit('chooseMaterialFromMaterialSection', material);
        else {
          setTimeout(() => {
            this.$root.$emit('chooseMaterialFromMaterialSection', material);
          }, 300);
        }
      } else if (material !== this.current_material) {
        this.$store.commit('cart/setCurrentFrame', null);
        this.$store.commit('cart/setCurrentEdge', null);
        this.$store.dispatch('cart/setCurrentMaterial', material);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
.justify-center {
  align-items: center;
}

#material-section {
  min-width: 100%;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  max-width: none;
  background: #e9e9e9;

  @media (max-width: 991px) {
    min-height: 500px;
  }

  .container-inner {
    padding-top: 70px;
    padding-bottom: 70px;

    @media (max-width: 1199px) {
      padding-right: 40px;
      padding-left: 40px;
    }

    @media (max-width: 991px) {
      padding-right: 0;
      padding-left: 0;
    }

    @media (max-width: 768px) {
      padding-top: 40px;
      padding-bottom: 70px;
    }
  }

  .material-boxes {
    @media (max-width: 1199px) {
      flex-wrap: wrap;
    }

    .material-box-item {
      max-width: 13.5rem;

      @media (max-width: 1400px) and (min-width: 1200px) {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
      }

      &:first-child {
        margin-left: 0 !important;
        padding-left: 0;
      }

      &:last-child {
        margin-right: 0 !important;
        padding-right: 0;
      }

      .material-box-image {
        overflow: hidden;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transition: all 0.2s ease;
        border: 3px solid transparent;

        img {
          transition: all 0.2s ease;
          border-radius: 50%;
          -webkit-transform: scale(1);
          transform: scale(1);

          @media (max-width: 1199px) {
            max-width: 9rem;
          }
        }

        &:hover {
          border: 3px solid #f7a600 !important;

          img {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
        }
      }

      .item-material-active {
        .material-box-image {
          border: 3px solid #f7a600 !important;
        }
      }

      .item-material-box-title {
        color: #f7a600;
        font-size: 1.25rem;
        line-height: 1.6rem;
        transition: color 0.2s ease;

        @media (max-width: 1400px) {
          font-size: 1.2rem;
          line-height: 1.4rem;
        }

        &:hover {
          color: #fdbe3c;
        }
      }

      .item-material-box-description {
        color: #58585a;
        font-size: 1.125rem;
        line-height: 1.563rem;

        @media (max-width: 1400px) {
          font-size: 1rem;
          line-height: 1.4rem;
        }
      }
    }
  }

  .material-box-wrapper {
    max-width: 70%;

    @media (max-width: 1400px) {
      max-width: 90%;
    }

    @media (max-width: 1199px) {
      max-width: 100%;
    }

    .material-box {
      .material-box-img {
        position: relative;

        &:after {
          position: absolute;
          bottom: 0;
          right: 0;
          content: '';
          width: 92px;
          height: 70px;
          background: transparent url('../assets/svg/batch-hd.svg') no-repeat top left;

          @media (max-width: 1199px) {
            display: none;
          }
        }

        img {
          max-width: 21.875rem;
        }
      }

      .material-box-text {
        max-width: 35rem;
        font-size: 1.375rem;
        line-height: 2rem;
        color: #58585a;
        text-align: left;

        @media (max-width: 1199px) {
          font-size: 1.1rem;
          line-height: 1.5rem;
          max-width: 100%;
          text-align: center;
        }

        @media (max-width: 991px) {
          font-size: 1.25rem;
          line-height: 1.875rem;
        }
      }
    }
  }

  .material-swiper {
    margin-right: -2.25rem;
    margin-left: -2.25rem;

    .swiper {
      height: unset !important;
      width: 100%;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      color: #58585a;

      .swiper-wrapper {
        align-items: flex-end;
      }

      .item-material-box {
        padding-top: 3px;

        .material-box-image {
          overflow: hidden;
          transition: all 0.2s ease;
          border: 2px solid transparent;

          img {
            max-width: 173px;
            transition: all 0.2s ease;
          }

          &:hover {
            border: 3px solid #f7a600;

            img {
              transform: scale(1.1);
            }
          }
        }

        &.item-material-active {
          .material-box-image {
            border: 3px solid #f7a600;
          }
        }

        .item-material-box-title {
          font-size: 1.063rem;
          line-height: 1.625rem;
          color: #f7a600;
          max-width: 173px;
        }

        .item-material-box-description {
          font-size: 0.938rem;
          line-height: 1.25rem;
          max-width: 173px;
        }
      }

      .swiper-slide {
        &:not(.swiper-slide-active) {
          .item-material-box {
            .material-box-image {
              img {
                max-width: 129px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
