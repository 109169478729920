<template>
  <div class="lines fixed top-0 h-screen z-10 hidden lg:block">
    <div class="bubbles-wrapper" :class="{ 'first-active': wizard_status[0], 'last-active': wizard_status[bubbles.length - 1] }">
      <div v-for="(bubble, index) in bubbles" :key="index" class="bubble" :class="{ active: wizard_status[index] }" @click="moveSection(index)">
        <span>{{ index + 1 }}</span>
        {{ bubble }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { moveTo } from '@/utils/fullpage';

export default {
  name: 'SideLine',
  data() {
    return {
      bubbles: ['Material', 'Konfiguration', 'Vorschau', 'Warenkorb', 'Anschrift', 'Zahlung']
    };
  },
  computed: {
    ...mapState({
      wizard_status: (state) => state.wizard.wizard_status
    })
  },
  methods: {
    moveSection(index) {
      if (this.wizard_status[index]) {
        moveTo(index + 3);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lines {
  margin-left: -25px;

  .bubbles-wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    // overflow: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 50%;
      width: 2px;
      margin-left: -1px;
      background-color: #bcbcbc;
      left: 50%;
    }

    &.first-active {
      &::before {
        background-color: #f7a600;
      }
    }

    &.last-active {
      &::after {
        background-color: #f7a600;
      }
    }

    &::before {
      bottom: calc(100% + 5px);
    }

    &::after {
      top: calc(100% + 5px);
    }

    .bubble {
      position: relative;
      font-size: 11px;
      color: #bcbcbc;
      font-weight: bold;

      span {
        display: block;
        background-color: #bcbcbc;
        margin: 0 auto 5px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        font-size: 14px;
        line-height: 1.8;
        color: #fff;
      }

      &:not(:first-child)::before {
        content: '';
        position: absolute;
        height: 50px;
        width: 2px;
        background-color: #bcbcbc;
        margin-left: -1px;
        bottom: calc(100% + 5px);
      }

      &.active {
        color: #f7a600;
        cursor: pointer;

        span {
          background-color: #f7a600;
        }

        &::before {
          background-color: #f7a600;
        }
      }
    }
  }
}
</style>
