// https://htmldom.dev/resize-an-image/
export const addBorder = function (image, width, height, bleedX, bleedY, color, doubleBorder) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();

    // Read the file
    reader.readAsDataURL(image);

    // Manage the `load` event
    reader.addEventListener('load', function (e) {
      // Create new image element
      const ele = new Image();
      ele.addEventListener('load', function () {
        // Create new canvas
        const canvas = document.createElement('canvas');

        // Draw the image that is scaled to `ratio`
        const context = canvas.getContext('2d');
        canvas.width = width + bleedX * 2;
        canvas.height = height + bleedY * 2;

        //draw bleed
        if (doubleBorder) {
          //grey border
          context.beginPath();
          context.strokeStyle = '#808080';
          context.fillStyle = '#808080';
          context.rect(0, 0, width + bleedX * 2, height + bleedY * 2);
          context.stroke();
          context.fill();

          //regular border
          context.beginPath();
          context.strokeStyle = color;
          context.fillStyle = color;
          context.rect(bleedX / 2, bleedY / 2, width + bleedX, height + bleedY);
          context.stroke();
          context.fill();
        } else {
          context.beginPath();
          context.strokeStyle = color;
          context.fillStyle = color;
          context.rect(0, 0, width + bleedX * 2, height + bleedY * 2);
          context.stroke();
          context.fill();
        }

        context.drawImage(ele, bleedX, bleedY, width, height);

        // Get the data of resized image
        'toBlob' in canvas
          ? canvas.toBlob(function (blob) {
              resolve(blob);
            })
          : resolve(dataUrlToBlob(canvas.toDataURL()));
      });

      // Set the source
      ele.src = e.target.result;
    });

    reader.addEventListener('error', function () {
      reject();
    });
  });
};

export const scaleImage = function (image, maxH) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();

    // Read the file
    reader.readAsDataURL(image);

    // Manage the `load` event
    reader.addEventListener('load', function (e) {
      // Create new image element
      const ele = new Image();
      ele.addEventListener('load', function () {
        // Create new canvas
        const canvas = document.createElement('canvas');

        let ratio = 1;
        //rescale the image based on the max height
        if (ele.height > maxH) {
          ratio = maxH / ele.height;
        }

        // Draw the image that is scaled to `ratio`
        const context = canvas.getContext('2d');
        canvas.width = ele.width * ratio;
        canvas.height = ele.height * ratio;
        context.drawImage(ele, 0, 0, ele.width * ratio, ele.height * ratio);

        // Get the data of resized image
        'toBlob' in canvas
          ? canvas.toBlob(function (blob) {
              resolve(blob);
            })
          : resolve(dataUrlToBlob(canvas.toDataURL()));
      });

      // Set the source
      ele.src = e.target.result;
    });

    reader.addEventListener('error', function () {
      reject();
    });
  });
};

export const dataUrlToBlob = function (url) {
  const arr = url.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const str = atob(arr[1]);
  let length = str.length;
  const uintArr = new Uint8Array(length);
  while (length--) {
    uintArr[length] = str.charCodeAt(length);
  }
  return new Blob([uintArr], { type: mime });
};

export const dataURLtoFile = function (dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
