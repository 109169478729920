<template>
  <b-modal id="modal-cmyk" class="cmyk-modal" :hide-header="true" :hide-footer="true" centered no-close-on-esc no-close-on-backdrop>
    <div class="modal-inside">
      <h2>Du hast ein Bild im CMYK-Modus hochgeladen.</h2>
      <p>
        Leider können wir nur Bilder im RGB-Modus verarbeiten (sRGB). Die Konvertierung erfolgt automatisch. Wir garantieren keine 100% Farbverbindlichkeit.
        Sollen wir eine Konvertierung vornehmen?
      </p>
      <div class="buttons text-right">
        <a class="btn btn-hollow mr-3" @click="$emit('accept-convert')">Ja, konvertieren</a>
        <a class="btn btn-primary" @click="$emit('reject-convert')">Neues Bild hochladen</a>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CMYKModal'
};
</script>

<style lang="scss">
@import '../../assets/css/baseModal.scss';

#modal-cmyk {
  .modal-body {
    padding: 5.625rem 4.625rem 4.375rem 7.5rem;

    @media (max-width: 768px) {
      padding: 2.5rem 2rem;
    }

    .modal-inside {
      h2 {
        margin-bottom: 2rem;

        @media (max-width: 768px) {
          margin-bottom: 1.5rem;
        }
      }

      p {
        margin-bottom: 2.25rem;

        @media (max-width: 768px) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
</style>
