import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar';

axios.defaults.withCredentials = process.env.NODE_ENV !== 'production'; // CORS cookies for local dev
// axios.defaults.baseURL = (process.env.NODE_ENV !== 'production') ? 'http://poster.local/' : '';
axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'https://prelive.posterkoenig.ch/' : '';

export default {
  getSession(cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/get-session';
    axios.get(url).then((r) => {
      cb(r);
    });
  },
  getMaterials(cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/material-list';
    axios.get(url).then((r) => {
      cb(r.data);
    });
  },
  getMaterialDetails(material_id, frame_id, edge_id, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/material-detail';
    axios
      .get(url, {
        params: {
          artikelgruppe1: material_id,
          artikelgruppe2: frame_id ? frame_id : '',
          artikelgruppe3: edge_id ? edge_id : ''
          // select:
          //   '=nr|bez|bez2|bild|artikelgruppe1|artikelgruppe2|artikelgruppe3|meta_title|additional|bild|breite|hoehe|aspect_ratio|sorting|preis_brutto|preis_netto|steuer|zubehoer'
        }
      })
      .then((r) => {
        cb(r.data);
      });
  },
  userLogin(login, password, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/user-login';
    axios
      .post(url, {
        password: password,
        user: login
      })
      .then((r) => {
        cb(r);
      });
  },
  userLogout(cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/user-logout';
    axios.get(url).then((r) => {
      document.cookie = 'session=' + r.data.token + ';path=/';
      cb(r);
    });
  },
  getUser(cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/get-user';
    axios.get(url).then((r) => {
      cb(r);
    });
  },
  userRegister(data, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/user-register';
    axios.post(url, data).then((r) => {
      cb(r);
    });
  },
  resetPassword(data, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/user-reset-password';
    axios.put(url, data).then((r) => {
      cb(r);
    });
  },
  forgetPassword(data, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/user-forget-password';
    axios.post(url, data).then((r) => {
      cb(r);
    });
  },
  updateBillingAddress(data, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/update-billing-address';
    axios.put(url, data).then((r) => {
      cb(r);
    });
  },
  getBillingAddress(cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/get-billing-address';
    axios.get(url).then((r) => {
      cb(r);
    });
  },
  getShippingAddress(cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/get-shipping-address';
    axios.get(url).then((r) => {
      cb(r);
    });
  },
  getOrderHistory(cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/get-order-history';
    axios.get(url).then((r) => {
      cb(r);
    });
  },
  updateShippingAddress(data, address_exist, cb) {
    var url_add = '/wp-content/plugins/posterkoenig-plugin/api.php/add-address';
    var url_update = '/wp-content/plugins/posterkoenig-plugin/api.php/update-address';

    if (address_exist) {
      axios.put(url_update, data).then((r) => {
        cb(r);
      });
    } else {
      axios.post(url_add, data).then((r) => {
        cb(r);
      });
    }
  },
  getCart(cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/get-basket';
    axios.get(url).then((r) => {
      cb(r);
    });
  },
  addToCart(data, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/add-to-basket';
    axios.post(url, data).then((r) => {
      cb(r);
    });
  },
  removeFromCart(position, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/remove-from-basket';
    axios
      .delete(url, {
        params: {
          pos: position
        }
      })
      .then((r) => {
        cb(r);
      });
  },
  uploadImage(file, position, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/upload-to-basket';
    var fileReader = new FileReader();

    fileReader.onloadend = () => {
      var form = new FormData();
      form.append('file', file);
      form.append('position', position);
      axios
        .post(url, form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((r) => {
          cb(r);
        });
    };
    fileReader.readAsDataURL(file);
  },
  updateBasketItems(basket, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/update-basket-items';
    axios.post(url, basket).then((r) => {
      cb(r);
    });
  },
  updateBasketAddress(basket, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/update-basket-address';
    axios.post(url, basket).then((r) => {
      cb(r);
    });
  },
  applyCoupon(coupon, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/apply-coupon';
    axios.post(url, { coupon: coupon }).then((r) => {
      cb(r);
    });
  },
  orderBasket(payment_method, cb) {
    loadProgressBar(null, axios);
    const url = '/wp-content/plugins/posterkoenig-plugin/api.php/order-basket';
    const options = {
      benutzerkonto_erstellen: false,
      zahlsystem: {
        urls: {
          abort: `${window.location.origin}${window.location.pathname}?abort`,
          error: `${window.location.origin}${window.location.pathname}?error`,
          success: `${window.location.origin}${window.location.pathname}?success`
        }
      }
    };

    axios.post(url, options).then((r) => {
      if (cb) cb(r.data);
    });
  },
  confirmPayment(cb) {
    const url = '/wp-content/plugins/posterkoenig-plugin/api.php/payment';
    const options = {
      paymentResult: 'success'
    };

    axios.post(url, options).then((r) => {
      if (cb) cb(r.data);
    });
  },
  async processImageConvertAPI(file, width, height) {
    const url = '/wp-content/plugins/posterkoenig-plugin/api.php/process-image-convert-api';

    const form = new FormData();
    form.append('file', file);
    form.append('width', parseInt(width));
    form.append('height', parseInt(height));

    const axiosResponse = await axios.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return axiosResponse;
  },
  async convertToSRGBConvertAPI(file) {
    const url = '/wp-content/plugins/posterkoenig-plugin/api.php/convert-to-srgb-convert-api';

    const form = new FormData();
    form.append('file', file);

    const axiosResponse = await axios.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return axiosResponse;
  },
  async convertPNGToJPGConvertAPI(file) {
    const url = '/wp-content/plugins/posterkoenig-plugin/api.php/convert-png-to-jpg-convert-api';

    const form = new FormData();
    form.append('file', file);

    const axiosResponse = await axios.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return axiosResponse;
  },
  uploadToFTP(fileStream, fileName, cb) {
    //console.log(fileName)
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/upload-to-ftp';
    var form = new FormData();
    form.append('file_stream', fileStream);
    form.append('file_name', fileName);
    axios
      .post(url, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((r) => {
        cb(r);
      });
  },
  getPDFLink(pdf_link, cb) {
    var url = '/wp-content/plugins/posterkoenig-plugin/api.php/get-pdf-token';
    axios.post(url, { pdf_link: pdf_link }).then((r) => {
      cb(r);
    });
  }
};
