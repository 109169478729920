<template>
  <b-modal id="modal-image-too-small" class="image-too-small-modal" :hide-header="true" :hide-footer="true" centered no-close-on-esc no-close-on-backdrop>
    <img class="hidden md:block" src="../../assets/svg/image-too-small.svg" alt="Image too small graphic" />

    <div class="modal-inside">
      <h2>Auflösung zu klein</h2>
      <p>Die Auflösung deines Bildes ist zu klein. Versuche es mit einem Bild das mindestens 800 x 800 Pixel gross ist.</p>
    </div>

    <div class="modal-close" @click="$bvModal.hide('modal-image-too-small')">
      <svg xmlns="http://www.w3.org/2000/svg" width="30.751" height="30.752" viewBox="0 0 30.751 30.752">
        <g id="Group_115" data-name="Group 115" transform="translate(-1404.146 -178.146)">
          <line id="Line_13" data-name="Line 13" x2="30.044" y2="30.044" transform="translate(1404.5 178.5)" fill="none" stroke="#707070" stroke-width="1" />
          <line id="Line_14" data-name="Line 14" y1="30.044" x2="30.044" transform="translate(1404.5 178.5)" fill="none" stroke="#707070" stroke-width="1" />
        </g>
      </svg>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ImageTooSmall'
};
</script>

<style lang="scss">
@import '../../assets/css/baseModal.scss';

#modal-image-too-small {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 772px;
    }

    .modal-content {
      .modal-body {
        padding: 3.5rem 5em 3.5rem 4.125rem;

        @media (max-width: 768px) {
          padding: 3.25rem 2rem 2.5rem;
        }

        .modal-inside {
          h2 {
            margin-bottom: 0.875rem;
          }

          p {
            color: #f02d2d;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
